import React from 'react';
import Opening from "../../components/pages/business/Opening";
import MainFashion from '../../components/pages/MainFashion';

const TaNaModa = () => (
  <MainFashion page="abrir-empresa-ta-na-moda">
    <Opening />
  </MainFashion>
)

export default TaNaModa;