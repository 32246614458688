import React, { useState } from 'react';
import {
  Typography,
  TextField,
  FormHelperText,
  MenuItem,
  Box,
  makeStyles,
} from '@material-ui/core';
import axios from 'axios';
import PropTypes from 'prop-types';
import InputWrap from '../atoms/InputWrap';
import StyledButton from '../atoms/StyledButton';
import MaskedTextField from '../molecules/MaskedTextField';
import AutocompleteCityUF from './AutocompleteCityUF';
import {
  ActivityOptions,
  FormatOptions,
  SegmentOptions,
} from '../functions/Options';
import {
  EmailValidation,
  LengthValidation,
  NameValidation,
  ObjectValidation,
  PhoneValidation,
  LastNameValidation,
} from '../functions/FormValidation';
import Hubspot from '../functions/Hubspot';
import { getPlanPrice, getPlanName } from '../functions/HowMuch';
import { InfashionFormOptions } from '../../static/files/Campaigns/TaNaModa';
import AllowedCities from '../../static/files/AllowedCities.json';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: '25px',

    '& .labels': {
      color: `${theme.palette.midGrey.main} !important`,
      marginTop: '30px',
    },
    '& .MuiFormHelperText-root': {
      color: theme.palette.error.main,
      marginBottom: '20px',
    },
  },
  lastInputs: {
    marginBottom: '30px',

    '& .MuiFormControl-root, & .MuiAutocomplete-root': {
      width: '100%',
      marginTop: '10px',
    },
  },
  buttonWrap: {
    display: 'grid',
    alignItems: 'center',
    justifyItems: 'center',
  },
}));

const CampaignOpenCompanyForm = ({
  name,
  setResultData,
  isLoading,
  setIsLoading,
}) => {
  const classes = useStyles();
  const [error, setError] = useState({});
  const [data, setData] = useState({});
  const { businessSegment, activities } = InfashionFormOptions.openCompany;

  const validateFields = () => (
    ObjectValidation(data, 9)
    && LengthValidation(data)
    && NameValidation(data)
    && LastNameValidation(data)
    && PhoneValidation(data)
    && EmailValidation(data)
  );

  const isFormValid = () => {
    try {
      return validateFields();
    } catch (e) {
      return false;
    }
  };

  const validateForm = () => {
    try {
      if (validateFields()) {
        setError({});
      }
    } catch (e) {
      setError({ field: e.fileName, message: e.message });
      return false;
    }
    return true;
  };

  const changeData = (value, field) => {
    setData({ ...data, [field]: value });
    setError({});
  };

  const onSubmit = async (event) => {
    event.preventDefault();

    if (validateForm()) {
      setIsLoading(true);

      const portalId = process.env.GATSBY_HUBSPOT_PORTAL_ID;
      const formGuid = process.env.GATSBY_HUBSPOT_ABRIR_EMPRESA_FORM_GUID;

      const cidade = data.cityName;

      // Retorna a cidade ou undefined caso não é válida
      const isAllowedCity = AllowedCities.cities.find((city) => city.name == cidade) === undefined;

      const url = `https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${formGuid}`;

      const dataHubspot = {
        fields: [
          {
            name: 'firstname',
            value: data.name,
          },
          {
            name: 'lastname',
            value: data.lastName,
          },
          {
            name: 'phone',
            value: data.phone,
          },
          {
            name: 'email',
            value: data.email,
          },
          {
            name: 'atividade',
            value: data.activity,
          },
          {
            name: 'segmento_atuacao',
            value: data.segment,
          },
          {
            name: 'cidade_estado',
            value: `${data.cityName} - ${data.state}`,
          },
          {
            name: 'campanha_interna',
            value: 'CC0011',
          },
        ],
        context: {
          pageUri: window.location.href,
          pageName: document.title,
        },
      };
      try {
        const cloudfare = await axios.get('https://www.cloudflare.com/cdn-cgi/trace');
        if (cloudfare.data) {
          const ipInfo = cloudfare.data.split('\n')[2];
          if (ipInfo.indexOf('ip=') > -1) {
            dataHubspot.context.ipAddress = ipInfo.replace('ip=', '');
          }
        }

        await axios.post(url, dataHubspot);
        setResultData(isAllowedCity);
        setIsLoading(false);
      } catch (err) {
        setIsLoading(false);
        setError({ message: 'Desculpe, ocorreu um erro. Tente novamente mais tarde!' });
      }
    }
  };

  const handleCityUFChange = (value) => {
    if (value) {
      setData({
        ...data,
        state: value.stateInitial,
        city: value.code,
        cityName: value.name,
      });
    } else {
      setData({
        ...data,
        state: null,
        city: null,
        cityName: null,
      });
    }
  };

  return (
    <Box className={classes.root}>
      <form
        onSubmit={(e) => onSubmit(e)}
        className="FORM-QUANTO-CUSTA-ABRIR-EMPRESA"
        name={name}
      >
        <Typography variant="button" className="labels">Dados pessoais</Typography>
        <InputWrap>
          <TextField
            label="Nome"
            name="name"
            value={data.name}
            onChange={(e) => changeData(e.target.value, 'name')}
            error={error.field === 'name'}
            disabled={isLoading}
          />

          <TextField
            label="Sobrenome"
            name="lastName"
            value={data.lastName}
            onChange={(e) => changeData(e.target.value, 'lastName')}
            error={error.field === 'lastName'}
            disabled={isLoading}
          />

          <MaskedTextField
            onChange={(e) => changeData(e.target.value, 'phone')}
            mask="+55 (99) 99999-9999"
            textFieldProps={{
              label: 'Telefone',
              name: 'phone',
              type: 'tel',
              value: data.phone,
              floating: true,
              error: error.field === 'phone',
            }}
            InputProps={{
              disabled: isLoading,
            }}
          />

          <TextField
            label="E-mail"
            name="email"
            type="email"
            value={data.email}
            onChange={(e) => changeData(e.target.value, 'email')}
            error={error.field === 'email'}
            disabled={isLoading}
          />
        </InputWrap>

        <Typography variant="button" className="labels">Sobre sua empresa</Typography>
        <InputWrap hasMargin={false}>
          <TextField
            label="Qual será a atividade?"
            name="atividade"
            value={data.activity}
            onChange={(e) => changeData(e.target.value, 'activity')}
            disabled={isLoading}
            select
          >
            {activities.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>

          <TextField
            label="Qual segmento de atuação?"
            name="segmento_atuacao"
            value={data.segment}
            onChange={(e) => changeData(e.target.value, 'segment')}
            disabled={isLoading}
            select
          >
            {businessSegment.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </InputWrap>

        <Box className={classes.lastInputs}>
          <AutocompleteCityUF
            onChange={(value) => handleCityUFChange(value)}
            value={{
              stateInitial: data.state,
              code: data.city,
              name: data.cityName,
            }}
            disabled={isLoading}
          />
        </Box>

        <FormHelperText>{error.message}</FormHelperText>

        <Box onClick={validateForm} className={classes.buttonWrap}>
          <StyledButton
            text="CADASTRAR NA PROMOÇÃO"
            type="submit"
            disabled={!isFormValid() || isLoading}
            variant="tnm"
            color="tnm"
            width="100%"
          />
        </Box>
      </form>
    </Box>
  );
};

CampaignOpenCompanyForm.propTypes = {
  name: PropTypes.string.isRequired,
  data: PropTypes.shape().isRequired,
  setData: PropTypes.func.isRequired,
  setResultData: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  setIsLoading: PropTypes.func.isRequired,
  color: PropTypes.string,
};

CampaignOpenCompanyForm.defaultProps = {
  color: 'info',
};

export default CampaignOpenCompanyForm;
