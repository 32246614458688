import React, { useState } from 'react';
import {
  Grid,
  Typography,
  makeStyles,
  Box,
} from '@material-ui/core';
import HeroBackground from './HeroBackground';
import FormCard from '../atoms/FormCard';
import StyledDrawer from '../atoms/StyledDrawer';
import StyledButton from '../atoms/StyledButton';
import OpeningFormDrawer from '../organisms/OpeningFormDrawer';
import HeaderInFashion from '../organisms/HeaderInFashion';
import { useDrawerContext, useStateDrawerContext } from '../../contexts/DrawerContext';
import HeroBackgroundTaNaModa from './HeroBackgroundTaNaModa';
import AdvantageForm from './AdvantageForm';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    width: '100%',
  },
  // Não mexer, por aqui ta tudo certo
  picture: {
    // marginTop: '4%',
    '@media screen and (max-width: 959px)': {
      display: 'none',
    },
  },
  button: {
    display: 'none',
    maxWidth: '85%',

    '@media screen and (max-width: 959px)': {
      display: 'block',
      maxWidth: '100%',
    },
  },
  boxAdvantage: {
    display: 'none',
    '@media screen and (max-width: 959px)': {
      display: 'block',
    },
  },
  hero: {
    minHeight: '50vh',
  },
}));

const OpeningHero = () => {
  const classes = useStyles();

  const { setOpenDrawer } = useDrawerContext();
  const { openDrawer } = useStateDrawerContext();

  return (

    <>
      <HeaderInFashion openDrawer={() => setOpenDrawer(true)} />
      <HeroBackgroundTaNaModa page="abrir-empresa">
        <Grid container spacing={2} className={classes.root}>
          <Grid item md={6} xs={12} className="text">
            <Typography className="span">
              {/* PARA NÓS, O QUE TÁ NA MODA É <br /> EMPREENDER ECONOMIZANDO */}
              <strong>Tá na moda</strong>
              {' '}
              empreender economizando
            </Typography>
            <Typography variant="h3" component="h1" className="title">
              Abra Empresa pagando apenas taxas e
              ainda
              {' '}
              <strong>economize na contabilidade</strong>
            </Typography>

            {/* <Typography variant="body1" component="h2" className={classes.description}>
            Sem cobrança de honorários, Certificado Digital
            gratuito e mais 80% de desconto no primeiro mês de contabilidade
            </Typography> */}
            <Box className={classes.boxAdvantage}>
              <AdvantageForm form="openCompany" colorIcon="#FFFFFF" />
            </Box>
            <Box className={classes.button}>
              <StyledButton
                text="QUERO APROVEITAR"
                variant="tnm"
                color="tnm"
                width="100%"
                onClick={() => setOpenDrawer(true)}
              />
            </Box>

          </Grid>

          <Grid item md={6} xs={12} className={classes.picture}>
            <FormCard color="info" taNaModa className={classes.hero}>
              <OpeningFormDrawer advantages="openCompany" />
            </FormCard>
          </Grid>
        </Grid>

        {/* Mobile */}
        <StyledDrawer
          open={openDrawer}
          setOpen={setOpenDrawer}
          taNaModa
        >
          <OpeningFormDrawer noHeight />
        </StyledDrawer>
      </HeroBackgroundTaNaModa>
    </>
  );
};

export default OpeningHero;
