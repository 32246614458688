import React, { useState, useEffect } from 'react';
import SwipeableViews from 'react-swipeable-views';
import {
  Box,
  makeStyles, Typography,
} from '@material-ui/core';

import HowMuchOpenCompany from './HowMuchOpenCompany';

import FeedbackMessage from '../molecules/FeedbackMessage';
import CheckIcon from '../../static/images/check-icon-outline.svg';
import AdvantageForm from '../molecules/AdvantageForm';
import CampaignOpenCompanyForm from './CampaignOpenCompanyForm';
import CenteredLoading from '../atoms/CenteredLoading';

const useStyles = makeStyles((theme) => ({

  overflowHide: {
    marginTop: '5%',
    '& .react-swipeable-view-container div': {
      overflow: 'hidden',
    },
  },
  form: {
    padding: '15px 30px 40px 30px',

    '@media screen and (max-width: 959px)': {
      padding: '15px 10px 40px 10px',
    },
  },
}));

const OpenigFormDrawer = ({ noHeight, advantages }) => {
  const classes = useStyles();
  const [resultData, setResultData] = useState(null); // aqui
  const [isLoading, setIsLoading] = useState(false);
  const [tabValue, setTabValue] = useState(0);

  useEffect(() => {
    if (resultData !== null) {
      setTabValue(1);
    }
  }, [resultData]);

  return (
    <SwipeableViews
      index={tabValue}
      className={classes.overflowHide}
    >
      <Box className={classes.form}>
        <CampaignOpenCompanyForm
          name="Abrir Empresa | Ta na moda"
          isLoading={isLoading}
          setResultData={setResultData}
          setIsLoading={setIsLoading}
        />
        <AdvantageForm form={advantages} />
        {isLoading && <CenteredLoading />}
      </Box>
      <div>
        <FeedbackMessage noHeight={noHeight} error={resultData} />
      </div>
    </SwipeableViews>
  );
};

export default OpenigFormDrawer;
